var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("page-section", { style: _vm.styles.fv, attrs: { id: "fv" } }, [
    _c(
      "div",
      [
        _c("div", { style: _vm.styles.title, attrs: { id: "title" } }, [
          _vm._v(" Tuttiの機能 "),
        ]),
        _c("img", {
          style: _vm.styles.figFv,
          attrs: { src: require("@/assets/web-figures/fv.png"), id: "fig-fv" },
        }),
        _c("contact-button", {
          staticClass: "mt-12",
          style: _vm.styles.contactButton,
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }