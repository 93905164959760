var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "page-section",
    { attrs: { id: "section" } },
    [
      _c("page-section-title", {
        attrs: {
          title: "Tuttiでラベリング可能なデータの種類",
          subtitle:
            "Tuttiでは、様々なラベリングシステムの構築にお使いいただける<br>\n                  数々のページテンプレートと、それらを自由にカスタマイズ頂ける環境をご提供します。<br>\n                  複数のデータ種類を扱うラベリングのフローを設計することも可能です。",
        },
      }),
      _c(
        "v-row",
        { staticClass: "justify-center" },
        [
          _c(
            "v-col",
            {
              staticStyle: { "text-align": "center" },
              attrs: { cols: _vm.$vuetify.breakpoint.forPC ? 4 : 6 },
            },
            [
              _c("img", {
                staticClass: "data-type-fig",
                style: _vm.styles.dataTypeFig,
                attrs: {
                  src: require("@/assets/web-figures/data-type-01.png"),
                },
              }),
            ]
          ),
          _c(
            "v-col",
            {
              staticStyle: { "text-align": "center" },
              attrs: { cols: _vm.$vuetify.breakpoint.forPC ? 4 : 6 },
            },
            [
              _c("img", {
                staticClass: "data-type-fig",
                style: _vm.styles.dataTypeFig,
                attrs: {
                  src: require("@/assets/web-figures/data-type-02.png"),
                },
              }),
            ]
          ),
          _c(
            "v-col",
            {
              staticStyle: { "text-align": "center" },
              attrs: { cols: _vm.$vuetify.breakpoint.forPC ? 4 : 6 },
            },
            [
              _c("img", {
                staticClass: "data-type-fig",
                style: _vm.styles.dataTypeFig,
                attrs: {
                  src: require("@/assets/web-figures/data-type-03.png"),
                },
              }),
            ]
          ),
          _c(
            "v-col",
            {
              staticStyle: { "text-align": "center" },
              attrs: { cols: _vm.$vuetify.breakpoint.forPC ? 4 : 6 },
            },
            [
              _c("img", {
                staticClass: "data-type-fig",
                style: _vm.styles.dataTypeFig,
                attrs: {
                  src: require("@/assets/web-figures/data-type-04.png"),
                },
              }),
            ]
          ),
          _c(
            "v-col",
            {
              staticStyle: { "text-align": "center" },
              attrs: { cols: _vm.$vuetify.breakpoint.forPC ? 4 : 6 },
            },
            [
              _c("img", {
                staticClass: "data-type-fig",
                style: _vm.styles.dataTypeFig,
                attrs: {
                  src: require("@/assets/web-figures/data-type-05.png"),
                },
              }),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }