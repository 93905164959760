// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/web-figures/fv-background.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "\n#fv[data-v-4d17ffbd] {\n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n    background-position: center;\n    background-repeat: no-repeat;\n    background-size: 100% 100%;\n    width: 100%;\n    margin-bottom: 180px;\n    position: relative;\n}\n#title[data-v-4d17ffbd] {\n    font-weight: 700;\n}\n#fig-fv[data-v-4d17ffbd] {\n    position: absolute;\n}\n", ""]);
// Exports
module.exports = exports;
