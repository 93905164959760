var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    {
      class: _vm.flexDirection,
      style: _vm.styles.wrapper,
      attrs: { align: "center" },
    },
    [
      _c(
        "v-col",
        {
          class: ["feature-detail", _vm.bodyColumnPadding],
          attrs: { cols: _vm.$vuetify.breakpoint.forPC ? 6 : 12 },
        },
        [
          _c("p", {
            style: _vm.styles.title,
            attrs: { id: "title" },
            domProps: { innerHTML: _vm._s(_vm.title) },
          }),
          _c(
            "div",
            [
              _vm._l(_vm.body, function (item, i) {
                return [
                  _c(
                    "div",
                    { key: "feature-detail-" + _vm.index + "-" + i },
                    [
                      "subtitle" in item
                        ? _c("text-horizontal-line", {
                            attrs: {
                              left: "",
                              text: item.subtitle,
                              "font-style": _vm.styles.subtitle,
                            },
                          })
                        : _vm._e(),
                      _c("p", {
                        style: _vm.styles.body,
                        domProps: { innerHTML: _vm._s(item.body) },
                      }),
                    ],
                    1
                  ),
                ]
              }),
            ],
            2
          ),
          _c("div", {
            staticClass: "background",
            style: _vm.styles.background,
          }),
        ]
      ),
      _c(
        "v-col",
        {
          class: ["figures", "text-center", _vm.figureColumnPadding],
          style: _vm.styles.figures,
          attrs: { cols: _vm.$vuetify.breakpoint.forPC ? 6 : 12 },
        },
        _vm._l(_vm.figSrcs, function (src, i) {
          return _c(
            "div",
            { key: "feature-detail-figure-" + _vm.index + "-" + i },
            [_c("img", { staticClass: "figure", attrs: { src: src } })]
          )
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }