var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "page-section",
    [
      _c("page-section-title", { attrs: { title: "Tuttiの特徴" } }),
      _c("feature-detail-boxes", {
        attrs: {
          index: 0,
          title: "迅速なラベリングシステム開発",
          body: [
            {
              subtitle: "豊富なページテンプレート",
              body: "ラベリングを行いたい対象のデータがどのようなメディア（例：音声、画像、テキスト）なのか、\n                       またどのような単位（例：データ全体、区間ごと、空間ごと）なのかは、構築したいデータセットに\n                       よって様々です。Tuttiには、どんなケースのラベリングにも対応できるようにたくさんの\n                       作業用ウェブページのテンプレートが揃っており、また現在もその種類は増え続けています。",
            },
            {
              subtitle: "自由なページ編集機能",
              body: "ページテンプレートの種類が豊富でも、いくつかのユースケースでは機能追加や見た目の修正などの\n                       変更が必要となってしまいます。そんな時でも、Tuttiのテンプレートは自由に編集することが\n                       できる設計になっているので心配は要りません。HTML、CSS、JavaScriptといった標準的な\n                       ウェブプログラミングスキルで、何の制約もない柔軟なページデザインの変更を行うことが出来ます。",
            },
          ],
          "fig-srcs": [
            require("@/assets/web-figures/feature-detail-01-01.png"),
            require("@/assets/web-figures/feature-detail-01-02.png"),
            require("@/assets/web-figures/feature-detail-01-03.png"),
          ],
        },
      }),
      _c("feature-detail-boxes", {
        attrs: {
          index: 1,
          title: "高機能なラベリング作業フロー設計",
          body: [
            {
              subtitle: "教育や作業品質チェックも一つの画面で",
              body: "正確で効率的なデータラベリングのためには、作業画面が１ページで事足りるかと言うと、多くの場合\n                       そうではありません。例えば、データセット全体のラベリング精度を高めるために、各作業者には最初に\n                       いくつかの練習問題を解いてもらいたいかもしれません。あるいは、途中で抜き打ちテストを実施して、\n                       もし結果が悪ければその人には一度抜けてもらったほうが良いかもしれません。<br>\n                       <br>\n                       Tuttiのフローチャート設計機能を使えば、用意した複数のページを、ラベリング作業を通して\n                       どんな順序・ルールで作業者へ提示するのか定義し、一つの画面内で自由に遷移させることができます。\n                       各作業者が辿ったフローの履歴ももちろん残るので、作業記録の分析にも役立ちます。",
            },
          ],
          "fig-srcs": [
            require("@/assets/web-figures/feature-detail-02-01.png"),
          ],
        },
      }),
      _c("feature-detail-boxes", {
        attrs: {
          index: 2,
          title: "作業プラットフォーム連携機能で<br>かんたん人海戦術",
          body: [
            {
              body: "Tuttiの管理画面では、ラベリングシステムの開発だけでなく、そのシステムをラベリング作業者へかんたんに配信する機能も備わっています。",
            },
            {
              subtitle: "組織内で手分けして作業",
              body: "作業者が組織内やパートナーコミュニティに属する場合は、登録作業者としてラベリング作業を行える\n                       ポータルサイトを別途ご提供できます。各自がどの程度ラベリングを行ったのかの進捗管理や、ノルマの\n                       設定など、組織毎の運用方針に合わせたグループ作業をサポートします。",
            },
            {
              subtitle: "クラウドソーシングでラベリング作業を外注",
              body: "現在Tuttiには、世界最大規模のクラウドソーシングプラットフォームであるAmazon Mechanical\n                       Turkとの各種連携機能が備わっています。煩雑なタスク外注関連の手続きがTuttiの管理画面上で\n                       完結しているため、クラウドワーカー報酬の支払い操作等もとても直感的です。Tuttiの開発チームの\n                       豊富な運用実績により、手厚い関連サポートも強みの一つです。",
            },
          ],
          "fig-srcs": [
            require("@/assets/web-figures/feature-detail-03-01.png"),
            require("@/assets/web-figures/feature-detail-03-02.png"),
          ],
        },
      }),
      _c("feature-detail-boxes", {
        attrs: {
          index: 3,
          title: "スムーズなデータ分析・活用を<br>実現するAPI",
          body: [
            {
              subtitle: "エンジニア向けのハイレベルなリソース操作",
              body: "Tuttiが管理するラベリングシステム・ラベリング対象データ・作業者の回答データ（ラベル）などの\n                       各種リソースは、PythonやJavaScriptのTutti用APIライブラリを用いて情報の取得や操作が\n                       行なえます。ブラウザの管理画面越しでは主に反復的な操作などが煩雑になることがありますが、APIを\n                       用いることでそれらを自動化することができます。<br>\n                       <br>\n                       さらに一歩進んだ使い方として、Human-in-the-loop（人間参加型）アプリを実装することもできます。\n                       例えば、Tutti APIではラベリング対象データの追加や、作業者の回答をプッシュ通知で受け取る機能を\n                       提供しています。これらを自作アプリの中で呼び出すことで、アプリに新しく入力されたデータをすぐ人手で\n                       検証し、結果をすぐアプリへ返すようなことも可能です。",
            },
          ],
          "fig-srcs": [
            require("@/assets/web-figures/feature-detail-04-01.png"),
          ],
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }